import React, { Component, FormEvent } from 'react';

import './contact.scss';
import Card from '../card/card';
import Button from '../button/button';

import {
  faEnvelope,
  faMapMarkedAlt,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as axios from 'axios';

/* eslint-disable-next-line */
export interface ContactProps {}

export class Contact extends Component<ContactProps> {
  state = {
    processing: false,
    message: '',
  };
  render() {
    return (
      <Card className="contact mt-4" background="plane" id="contact">
        <h2>Get in Touch</h2>
        <div className="underline"></div>
        <div className="contact_content d-flex flex-wrap justify-content-between mt-4">
          <div className="social">
            <div className="d-flex s-link">
              <FontAwesomeIcon icon={faEnvelope} />
              <div>
                <div className="s-link-title">Email Address</div>
                <div className="s-link-value">karedianoorsil@gmail.com</div>
              </div>
            </div>
            <div className="d-flex s-link">
              <FontAwesomeIcon icon={faMapMarkedAlt} />
              <div>
                <div className="s-link-title">Location</div>
                <div className="s-link-value">Toronto, ON Canada</div>
              </div>
            </div>
            <div className="d-flex s-link">
              <FontAwesomeIcon icon={faLinkedinIn} />
              <div>
                <div className="s-link-title">Linkedin</div>
                <div className="s-link-value">
                  <a
                    href="https://www.linkedin.com/in/karedianoorsil/"
                    target="__blank"
                  >
                    linkedin.com/karedianoorsil
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="email-form">
            <form onSubmit={(e) => this.sendMail(e)}>
              <div className="d-flex flex-wrap">
                <div className="mr-3">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Your Name"
                  />
                </div>
                <div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Your Email"
                  />
                </div>
              </div>
              <div className="mt-4 message-block">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Message"
                  rows={10}
                ></textarea>
              </div>
              <div className="message">{this.state.message}</div>
              <div className="d-flex align-items-center">
                <button id="reset" type="reset" style={{display: 'none'}}></button>
                <Button variant="secondary" icon={faPaperPlane} type="submit">
                  Send
                </Button>
                <div
                  className={
                    this.state.processing
                      ? `-processing circle mt-3 ml-3`
                      : 'circle mt-3 ml-3'
                  }
                >
                  <svg
                    className="circle__svg"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <linearGradient
                        id="linear"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop offset="0%" stop-color="#5f72bd" />
                        <stop offset="100%" stop-color="#9b23ea" />
                      </linearGradient>
                    </defs>
                    <circle
                      className="circle__svg-circle"
                      cx="50"
                      cy="50"
                      r="40"
                      stroke="url(#linear)"
                    />
                  </svg>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Card>
    );
  }

  private async sendMail(e: FormEvent) {
    e.preventDefault();
    this.setState({
      processing: true,
    });
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const message = (document.getElementById('message') as HTMLInputElement)
      .value;
    try {
      const result = await axios.default.post(
        `https://email-karedianoorsil.azurewebsites.net/api/Email?code=01fUUNNIPmiaChWlkdUXwZSkrjKZoLmp7kNcmlcsQuAHtaZJNWKgrg%3D%3D`,
        {
          name,
          email,
          message,
        }
      );
      this.setState({
        processing: false,
        message: result.data.message
      });
      document.getElementById('reset').click();
    } catch (error) {
      this.setState({
        processing: false,
        message: 'Something went wrong. Please try again later.'
      });
    }
    setTimeout(() => {
      this.setState({
        message: ''
      })
    }, 5000);
  }
}

export default Contact;
