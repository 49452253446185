import React, { Component } from 'react';

import './about.scss';
import { Button } from '../button/button';
import { Card } from '../card/card';

import { faEnvelope, faDownload } from '@fortawesome/free-solid-svg-icons';

/* eslint-disable-next-line */
export interface AboutProps {}

export class About extends Component<AboutProps> {
  render() {
    return (
      <Card className="about mt-4" background="plane" id="about">
        <h2>About</h2>
        <div className="underline"></div>
        <div className="about__content d-flex">
          <div className="display-picture">
            <img src="assets/nk.jpg" alt="Noorsil Karedia" />
          </div>
          <div className="content-block ml-4">
            <p className="about-para m-0">
              <span className="head-text">Hello,</span>
              <br /> <br />
              My name is Noorsil Karedia and I am a Web Developer. I live in
              Toronto which is known as financial capital as well as tech hub of
              Canada. I came to Canada in 2012 as an international student and
              persued Diploma in Computer Programming in Seneca College. My
              academic career mostly inlcuded working on number of projects in
              languages like C, C++, Java, .NET which helped me build a
              foundation for my programming carrer in this industry.
              <br />I started my first job in Canada, in a Canadian company in
              Toronto as a Software Developer where I work on large enterprise
              projects. <br /> My passion for programming also keeps me working
              on other side projects as a freelancer.
            </p>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="mr-3">
                <div className="d-flex mt-3">
                  <div className="info-label">Name:&nbsp;</div>
                  <div className="info-value">Noorsil Karedia</div>
                </div>
                <div className="d-flex mt-3">
                  <div className="info-label">Location:&nbsp;</div>
                  <div className="info-value">Toronto, ON Canada</div>
                </div>
              </div>
              <div>
   
                <div className="d-flex mt-3">
                  <div className="info-label">Email:&nbsp;</div>
                  <div className="info-value">karedianoorsil@gmail.com</div>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex flex-wrap">
              <div>
                <Button icon={faDownload}>Download CV</Button>
              </div>
              <div className="ml-3">
                <Button
                  variant="secondary"
                  icon={faEnvelope}
                  onClick={(e) => {
                    document.getElementById('nav-link-contact').click();
                  }}
                >
                  Hire Me
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default About;
