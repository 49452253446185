import React from 'react';
import { SideBar } from './side-bar/side-bar';
import { SiteBody } from './site-body/site-body';

import './app.scss';

import { ReactComponent as Logo } from './logo.svg';
import star from './star.svg';

export const App = () => {
  return (
    <div className="app container" id="app-container">
      <header>
        <SideBar />
      </header>
      <main>
        <SiteBody />
      </main>
    </div>
  );
};

export default App;
