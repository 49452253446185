import React, { Component } from 'react';

import './home.scss';
import { Card } from '../card/card';
import { Button } from '../button/button';

import { faArrowRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';

/* eslint-disable-next-line */
export interface HomeProps {}

export class Home extends Component<HomeProps> {
  render() {
    return (
      <Card
        className="summary d-flex align-items-center justify-content-center"
        background="gradient"
        id="home"
      >
        <div>
          <h2 className="name-heading">
            Hi there,
            <br />
            I’m Noorsil Karedia
          </h2>
          <div className="name-sub-heading">
            <div>I build awesome and functional websites.</div>
            <div>
              I'm a full time corporate developer and part time freelancer.{' '}
              <Button variant="textOnly" onClick={(e) => this.navigate('nav-link-about')}>
                Read more
              </Button>
            </div>
            <span></span>
          </div>
          <Button variant="secondary" icon={faEnvelope} onClick={(e) => {this.navigate('nav-link-contact')}}>
            Get In Touch
          </Button>
        </div>
      </Card>
    );
  }

  navigate(id: string) {
    document.getElementById(id).click();
  }
}

export default Home;
