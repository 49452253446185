import React, { Component } from 'react';

import './site-body.scss';

import Home from '../home/home';
import About from '../about/about';
import Skills from '../skills/skills';
import Experience from '../experience/experience';
import MobileNav from '../mobile-nav/mobile-nav';
import CertsAndAwards from '../certs-and-awards/certs-and-awards';
import Contact from '../contact/contact';

/* eslint-disable-next-line */
export interface SiteBodyProps {}

export class SiteBody extends Component<SiteBodyProps> {
  render() {
    return (
      <div className="sitebody">
        <MobileNav />
        <Home />
        <About />
        <Skills />
        <Experience />
        <CertsAndAwards />
        <Contact />
      </div>
    );
  }
}

export default SiteBody;
