import React, { Component } from 'react';

import './card.scss';

/* eslint-disable-next-line */
export interface CardProps {
  background: 'plane' | 'gradient';
  className: string;
  padding: 'condensed' | 'free';
  id: string;
}

export class Card extends Component<CardProps> {
  static defaultProps: CardProps = {
    background: 'plane',
    className: '',
    padding: 'free',
    id: '',
  };

  render() {
    return (
      <section
        id={this.props.id}
        className={`card -padding_${this.props.padding} -${this.props.background} `.concat(
          this.getClassNames()
        )}
      >
        {this.props.children}
      </section>
    );
  }

  getClassNames(): string {
    return this.props.className;
  }
}

export default Card;
