import React, { Component } from 'react';

import './button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

/* eslint-disable-next-line */
export interface ButtonProps {
  variant: 'primary' | 'secondary' | 'textOnly';
  icon?: IconProp;
  iconPosition: 'left' | 'right';
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export class Button extends Component<ButtonProps> {
  static defaultProps: ButtonProps = {
    variant: 'primary',
    type: 'button',
    iconPosition: 'left',
  };
  render() {
    return (
      <button
        className={`button mt-3 ${this.variant} ${this.iconPosition}`}
        type={this.props.type}
        onClick={(e) => this.clicked(e)}
      >
        {this.getIcon()}
        <span className={this.props.icon ? this.props.iconPosition === 'left' ? 'ml-3' : 'mr-3' : ''}>
          {this.props.children}
        </span>
      </button>
    );
  }

  private clicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  private get variant(): string {
    return `-${this.props.variant}`;
  }

  private get iconPosition(): string {
    return `-icon-${this.props.iconPosition}`;
  }

  private getIcon() {
    if (this.props.icon) {
      return <FontAwesomeIcon icon={this.props.icon} />;
    } else {
      return null;
    }
  }
}

export default Button;
