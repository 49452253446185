import React, { Component } from 'react';

import './mobile-nav.scss';
import { Card } from '../card/card';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* eslint-disable-next-line */
export interface MobileNavProps {}

export class MobileNav extends Component<MobileNavProps> {
  render() {
    return (
      <Card
        background="gradient"
        padding="condensed"
        className="mobile-nav d-flex mb-4 d-xs-block d-md-none align-items-center"
      >
        <div>
          <button className="drawer-trigger" onClick={this.toggleDrawer}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div>
          <div className="dp">
            <img src="assets/nk.jpg" alt=""/>
          </div>
        </div>
        <div className="brand">
          Noorsil K.
        </div>
      </Card>
    );
  }

  toggleDrawer() {
    const appContainer = document.getElementById('app-container');
    appContainer.classList.toggle('-sidebar-open');
    appContainer.classList.toggle('overflow-hidden');
  }
}

export default MobileNav;
