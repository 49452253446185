import React, { Component } from 'react';

import './experience.scss';
import { Card } from '../card/card';

/* eslint-disable-next-line */
export interface ExperienceProps {}

export class Experience extends Component<ExperienceProps> {
  render() {
    return (
      <Card className="experience mt-4" background="plane" id="experience">
        <h2>Experience</h2>
        <div className="underline"></div>
        <div className="experience-container mt-4">
          <div className="table">
            <div className="td trf first">
              <div></div>
            </div>
            <div className="td trf middle">
              <div className="time-bar">
                <div className="dot"></div>
              </div>
            </div>
            <div className="td trf last"></div>
            <div className="td first">
              <div>2018 - <span className="d-block d-sm-inline">Present</span></div>
            </div>
            <div className="td middle">
              <div className="time-bar">
                <div className="dot">
                  <div className="dot-inner"></div>
                </div>
              </div>
            </div>
            <div className="td last">
              <div className="exp-title">Front End Developer</div>
              <div className="exp-desc">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni
                aliquam culpa voluptatem explicabo laborum! Enim beatae deserunt
                optio eos reiciendis excepturi debitis consectetur autem quas
                repellendus. Commodi totam sit ex?
              </div>
            </div>
            <div className="td first">
              <div>2016 - <span className="d-block d-sm-inline">2018</span></div>
            </div>
            <div className="td middle">
              <div className="time-bar">
                <div className="dot">
                  <div className="dot-inner"></div>
                </div>
              </div>
            </div>
            <div className="td last">
              <div className="exp-title">Software Developer</div>
              <div className="exp-desc">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni
                aliquam culpa voluptatem explicabo laborum! Enim beatae deserunt
                optio eos reiciendis excepturi debitis consectetur autem quas
                repellendus. Commodi totam sit ex?
              </div>
            </div>
            <div className="td first">
              <div>2011 - <span className="d-block d-sm-inline">2012</span></div>
            </div>
            <div className="td middle">
              <div className="time-bar">
                <div className="dot">
                  <div className="dot-inner"></div>
                </div>
              </div>
            </div>
            <div className="td last">
              <div className="exp-title">Jr. Web Developer</div>
              <div className="exp-desc">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni
                aliquam culpa voluptatem explicabo laborum! Enim beatae deserunt
                optio eos reiciendis excepturi debitis consectetur autem quas
                repellendus. Commodi totam sit ex?
              </div>
            </div>
            <div className="td trl first">
              <div></div>
            </div>
            <div className="td trl middle">
              <div className="time-bar">
                <div className="dot"></div>
              </div>
            </div>
            <div className="td trl last"></div>
          </div>
        </div>
      </Card>
    );
  }
}

export default Experience;
