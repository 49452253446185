import React, { Component } from 'react';

import './certs-and-awards.scss';
import Card from '../card/card';

/* eslint-disable-next-line */
export interface CertsAndAwardsProps {}

export class CertsAndAwards extends Component<CertsAndAwardsProps> {
  render() {
    return (
      <Card background="plane" className="certs-and-awards mt-4" id="certificates">
        <h2>Certificates</h2>
        <div className="underline"></div>
        <div className="cert mt-4">
          <div className="cert-title">Gold Standard - Recognition for Commitment and Quality of Work</div>
          <div className="cert-sub-title">mobileLIVE Inc.</div>
          <div className="cert-image">
            <img src="assets/gold.jpg" className="w-100" alt=""/>
          </div>
        </div>
        <div className="cert mt-4">
          <div className="cert-title">Computer Programer - Diploma</div>
          <div className="cert-sub-title">Seneca Collge of Applied Arts and Technology</div>
          <div className="cert-image mt-3">
            <img src="assets/seneca.jpg" className="w-100" alt=""/>
          </div>
        </div>
        <div className="cert mt-4">
          <div className="cert-title">Angular</div>
          <div className="cert-sub-title">Udemy</div>
          <div className="cert-image mt-3">
            <img src="assets/udemy-cert.jpg" className="w-100" alt=""/>
          </div>
        </div>
        <div className="cert mt-4">
          <div className="cert-title">React</div>
          <div className="cert-sub-title">Udemy</div>
          <div className="cert-image mt-3">
            <img src="assets/udemy-react-cert.jpg" className="w-100" alt=""/>
          </div>
        </div>
      </Card>
    );
  }
}

export default CertsAndAwards;
