import React, { Component } from 'react';

import './skills.scss';
import { Card } from '../card/card';

import { ReactComponent as Angular } from '../../assets/icons/angular.svg';
import { ReactComponent as Bootstrap } from '../../assets/icons/bootstrap.svg';
import { ReactComponent as Express } from '../../assets/icons/express.svg';
import { ReactComponent as Git } from '../../assets/icons/git.svg';
import { ReactComponent as Html } from '../../assets/icons/html.svg';
import { ReactComponent as Ionic } from '../../assets/icons/ionic.svg';
import { ReactComponent as Javascript } from '../../assets/icons/javascript.svg';
import { ReactComponent as Jquery } from '../../assets/icons/jquery.svg';
import { ReactComponent as Mongo } from '../../assets/icons/mongo.svg';
import { ReactComponent as Nativescript } from '../../assets/icons/nativescript.svg';
import { ReactComponent as Nodejs } from '../../assets/icons/nodejs.svg';
import { ReactComponent as ReactSvg } from '../../assets/icons/react.svg';
import { ReactComponent as Reactivex } from '../../assets/icons/reactivexx.svg';
import { ReactComponent as Scss } from '../../assets/icons/scss.svg';
import { ReactComponent as Ts } from '../../assets/icons/typescript.svg';
import { ReactComponent as Webpack } from '../../assets/icons/webpack.svg';

/* eslint-disable-next-line */
export interface SkillsProps {}

export class Skills extends Component<SkillsProps> {
  render() {
    return (
      <Card className="skills mt-4" background="plane" id="skills">
        <h2>Skills</h2>
        <div className="underline"></div>
        <div className="skills-grid d-flex flex-wrap mt-5">
          <div className="skill-card">
            <Angular />
          </div>
          <div className="skill-card">
            <ReactSvg />
          </div>
          <div className="skill-card">
            <Ts />
          </div>
          <div className="skill-card">
            <Javascript />
          </div>
          <div className="skill-card">
            <Html />
          </div>
          <div className="skill-card">
            <Scss />
          </div>
          <div className="skill-card">
            <Nodejs />
          </div>
          <div className="skill-card">
            <Reactivex />
          </div>
          <div className="skill-card">
            <Jquery />
          </div>
          <div className="skill-card">
            <Bootstrap />
          </div>
          <div className="skill-card">
            <Ionic />
          </div>
          <div className="skill-card">
            <Nativescript />
          </div>
          <div className="skill-card">
            <Mongo />
          </div>
          <div className="skill-card">
            <Git />
          </div>
          <div className="skill-card">
            <Webpack />
          </div>
          <div className="skill-card">
            <Express />
          </div>
        </div>
      </Card>
    );
  }
}

export default Skills;
