import React, { Component } from 'react';
import { Card } from '../card/card';

import './side-bar.scss';

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

import {
  faHome,
  faUser,
  faAward,
  faMobileAlt,
  faWrench,
  faBriefcase,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

/* eslint-disable-next-line */
export interface SideBarProps {}

export class SideBar extends Component<SideBarProps> {
  state = {
    active: 'home',
  };



  private closeSidebar() {
    const appContainer = document.getElementById('app-container');
    appContainer.classList.remove('-sidebar-open', 'overflow-hidden');
  }

  private navigate(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    target: string
  ) {
    e.preventDefault();
    window.scrollTo({
      top: document.getElementById(target).offsetTop - 24,
      behavior: 'smooth',
    });
    this.setState({
      active: target,
    });
    this.closeSidebar();
  }

  private isActive(id: string) {
    return id === this.state.active ? '-active' : null;
  }

  render() {
    return (
      <Card background="gradient" padding="condensed" className="sidebar">
        <button
          className="close-button d-xs-block d-md-none"
          onClick={this.closeSidebar}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div
          className="scroll-container"
          data-simplebar
          data-simplebar-auto-hide="false"
        >
          <div className="section-top">
            <div className="display-pic">
              <img src="assets/nk.jpg" alt="" />
            </div>
            <h1 className="name-heading">Noorsil K.</h1>
            <div className="title-heading">Web Developer</div>
            <div className="nav-links mt-5">
              <div className="nav-link">
                <a
                  href="#home"
                  onClick={(e) => this.navigate(e, 'home')}
                  className={this.isActive('home')}
                  id="nav-link-home"
                >
                  <div>
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  <span>Home</span>
                </a>
              </div>
              <div className="nav-link">
                <a
                  href="#about"
                  onClick={(e) => this.navigate(e, 'about')}
                  className={this.isActive('about')}
                  id="nav-link-about"
                >
                  <div>
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <span>About</span>
                </a>
              </div>
              <div className="nav-link">
                <a
                  href="skills"
                  onClick={(e) => this.navigate(e, 'skills')}
                  className={this.isActive('skills')}
                  id="nav-link-skills"
                >
                  <div>
                    <FontAwesomeIcon icon={faWrench} />
                  </div>
                  <span>Skills</span>
                </a>
              </div>
              <div className="nav-link">
                <a
                  href="#experience"
                  onClick={(e) => this.navigate(e, 'experience')}
                  className={this.isActive('experience')}
                  id="nav-link-experience"
                >
                  <div>
                    <FontAwesomeIcon icon={faBriefcase} />
                  </div>
                  <span>Experience</span>
                </a>
              </div>
              <div className="nav-link">
                <a
                  href="certificates"
                  onClick={(e) => this.navigate(e, 'certificates')}
                  className={this.isActive('certificates')}
                  id="nav-link-certificates"
                >
                  <div>
                    <FontAwesomeIcon icon={faAward} />
                  </div>
                  <span>Certs &amp; Awards</span>
                </a>
              </div>
              <div className="nav-link">
                <a
                  href="#contact"
                  onClick={(e) => this.navigate(e, 'contact')}
                  className={this.isActive('contact')}
                  id="nav-link-contact"
                >
                  <div>
                    <FontAwesomeIcon icon={faMobileAlt} />
                  </div>
                  <span>Contact</span>
                </a>
              </div>
            </div>
          </div>
          <div className="section-bottom mt-4">
            <div className="social-links d-flex w-100">
              <div>
                <a href="https://github.com/nkaredia" target="__blank">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </div>
              <div>
                <a href="https://twitter.com/nkaredia93" target="__blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/in/karedianoorsil/" target="__blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
            <div className="copyright">&copy; 2020 Noorsil Karedia</div>
          </div>
        </div>
      </Card>
    );
  }
}

export default SideBar;
